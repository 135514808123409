import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import './articles.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function Articles() {
  return (
    <Content className='articles-root'>
      <Col className='articles-container' >
        <Row style={{alignItems: 'center'}}>
          <Typography>
            <Title className="articles-title">Articles</Title>
            <Paragraph className='articles-subtitle'>
              Know how your team works in the field. Empower your team, develop healthy work habits, and streamline processes to achieve exceptional results.
            </Paragraph>
          </Typography>
        </Row>
      </Col>
    </Content>
  )
}